import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Editor, Validators } from 'ngx-editor';
import { BehaviorSubject, Subject, of, share, startWith, switchMap, takeUntil } from 'rxjs';
import { CampaignService } from 'src/app/services/api/campaign.service';
import { EmailsService, PreviewResponse, emailList } from 'src/app/services/api/emails.service';
import { JobPostingsService, emailTemplates } from 'src/app/services/api/job-postings.service';
import { XrefService } from 'src/app/services/api/xref.service';
import { AuthService } from 'src/app/services/auth.service';
import { createSuccessResult } from 'src/app/utils/api-helpers';
import { successData, tapFinished, tapLoading, truthy } from 'src/app/utils/rxjs-operators';

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SendEmailModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  form = this.fb.group({
    template: new FormControl<string>('0'),
    from: [''],

    subject: new FormControl<any>(''),
    body: new FormControl<any>('', [Validators.required()]),
    send: [],
    days: []
  });

  submitted = false;
  editor: Editor;
  emailBody: string | undefined = '';
  emailTemplates: emailTemplates[] = [];

  emailTemplates$ = this.jobPostingService.getEmailTemplates().pipe(successData(), takeUntil(this.destroy$));

  emailTemplateId$ = new BehaviorSubject<number | undefined>(undefined);

  @ViewChild('success', { static: false }) successModal?: TemplateRef<any>;
  @ViewChild('notSent', { static: false }) notSentModal?: TemplateRef<any>;
  @ViewChild('preview', { static: false }) previewModal?: TemplateRef<any>;

  showAll = false;
  showAllRecipients = true;
  showVariables = false;
  CandidatesSelectedLabel = '{{Candidates Selected}}';
  previewResponse?: PreviewResponse;

  loading = false;

  recruiterEmail$ = this.emailsService.getRecruiterEmail().pipe(successData(), takeUntil(this.destroy$));

  @ViewChild('innerClose') close?: MatDialogClose;
  modalRef: any;
  outreachVars: any = [];

  outreachVars$ = this.xrefService.getOutreachVars().pipe(successData(), takeUntil(this.destroy$));
  errorMsg = '';

  emailList$ = this.emailsService.emailList({ jobId: this.data?.jobId, selectedCandidates: this.data.selectedCandidates.map((x: any) => x.profileRef) }).pipe(
    tapLoading(() => { this.emailListLoading = true; }),
    tapFinished(() => { this.emailListLoading = false; }),
    successData(),
    takeUntil(this.destroy$)
  );
  emailListLoading = false;
  @ViewChild('RTE') public rteObj?: any;

  private listboxEle: any;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SendEmailModalComponent>,
    public xrefService: XrefService,
    public jobPostingService: JobPostingsService,
    private dialog: MatDialog,
    private emailsService: EmailsService,
    private campaignService: CampaignService,
    private snackBar: MatSnackBar,
    public auth: AuthService
  ) {
    this.editor = new Editor();
  }

  ngOnInit() {
    //this.form.controls.to.setValue(Object.assign([], this.data.selectedCandidates));

    this.emailTemplates$.pipe(truthy(), takeUntil(this.destroy$)).subscribe(data => {
      this.emailTemplates = data;
      if (this.data.specificTemplate) {
        this.form.controls.template.setValue(this.emailTemplates.find(x => x.templateTypeId === this.data.specificTemplate)?.templateTypeId + '');
        this.form.controls.template.disable();
      }
    });

    this.form.controls.template.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(templateId => {
      this.campaignService
        .getEmailTemplate(this.data.jobId, String(templateId))
        .pipe(successData(), takeUntil(this.destroy$))
        .subscribe(value => {
          this.form.controls.body.setValue(value.emailBody);
          this.form.controls.subject.setValue(value.emailHeader);
        });
    });

    this.outreachVars$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const group = value.reduce((acc: any, curr) => {
        const key = curr.varType;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {});

      this.outreachVars = Object.keys(group).map(key => ({
        groupId: key,
        vars: group[key]
      }));
    });
    this.emailList$.pipe(takeUntil(this.destroy$)).subscribe(value => {

      this.emailsList = value;
      this.intDragandDrop();
    });

  }
  emailsList: emailList[] = [];

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.editor.destroy();
  }

  onUserRemoved(topping: emailList) {
    const toppings = this.emailsList as any[];
    this.removeFirst(toppings, topping);
    this.emailsList = toppings; // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        emailHeader: this.form.controls.subject?.value,
        emailBody: this.form.controls.body?.value,
        candidates: this.emailsList?.map(x => x.profileRef),
        templateTypeId: Number(this.form.controls.template.value || 0)
      };
      of(null)
        .pipe(
          switchMap(() => this.emailsService.sendEmail(this.data.jobId, body)),
          switchMap(result => {
            if (result.finished) {
              this.loading = false;

              if (result.success) {
                if (this.successModal) {
                  this.dialog.open(this.successModal, { minWidth: '500px' });
                }
                this.dialogRef?.close();
                this.modalRef?.close();
              } else {
                this.snackBar.open('An error occured, please try again later', 'Close', { duration: 5 * 1000 });
              }
            }

            return of(result);
          }),
          share(),
          startWith(createSuccessResult(undefined)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  exit() {
    if (this.notSentModal) this.modalRef = this.dialog.open(this.notSentModal, { minWidth: '500px' });
  }

  closeAll() {
    this.modalRef?.close();
    this.dialogRef?.close();
  }

  previewAction() {

    this.loading = true;

    of(null)
      .pipe(
        switchMap(() =>
          this.emailsService.preview(
            this.data.jobId,
            { emailHeader: this.form.controls.subject?.value, emailBody: this.form.controls.body?.value },
            this.emailsList.length > 0 ? this.emailsList[0].profileRef : ''
          )
        ),
        switchMap(result => {
          if (result.finished) {
            this.loading = false;

            if (result.success) {
              this.loading = false;
              this.previewResponse = result.data;

              if (this.previewModal) this.modalRef = this.dialog.open(this.previewModal, { minWidth: '80vw' });
            } else {
              this.snackBar.open('An error occured, please try again later', 'Close', { duration: 5 * 1000 });
            }
          }

          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();

  }

  clearTemplate() {
    this.form.patchValue({ template: '0' });
  }

  intDragandDrop() {
    this.listboxEle = document.getElementById('listbox');

    // DragStart event binding
    this.listboxEle.addEventListener('dragstart', (e: any) => {
      e.dataTransfer.setData('Text', e.target.innerText.split(' ')[0]);

    });
  }


}
