import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject, map, of, switchMap, takeUntil, tap, timer } from 'rxjs';
import { CandidateResponse } from 'src/app/enum';
import { ApiService, DataTable } from 'src/app/services/api.service';
import { CandidatesService } from 'src/app/services/api/candidates.service';
import { ResumesService } from 'src/app/services/api/resumes.service';
import { City, PositionType, WorkStatus, XrefService } from 'src/app/services/api/xref.service';
//import { AuthService } from 'src/app/services/auth.service';

import { mapSuccessData, successData, tapFinished, tapLoading, tapSuccess, truthy } from 'src/app/utils/rxjs-operators';
import { nullToUndefined } from 'src/app/utils/app.helpers';

@Component({
  selector: 'app-candidate-questionnaire',
  templateUrl: './candidate-questionnaire.component.html',
  styleUrls: ['./candidate-questionnaire.component.scss']
})
export class CandidateQuestionnaireComponent implements OnDestroy {
  private destroy$ = new Subject<void>();
  genders: any[] = [];
  races: any[] = [];
  veteranStatus: any[] = [];
  pronounsList: any[] = [];
  payType: any[] = [];
  positionType: any[] = [];
  workStatus: any[] = [];
  gender = '';
  race = '';
  veteran = '';
  selectedTab = 0;
  loading = false;
  profileRef = '';
  sus = '';
  CandidateResponse = CandidateResponse;
  candidateResponse = CandidateResponse.INTEREST;
  showQuestionnaire = 1;
  jobsMatched: any[] = [];
  candidate$ = new BehaviorSubject<any | undefined>(undefined);
  jwtParams$ = this.activatedRoute.queryParams.pipe(
    map(params => params),
    truthy(),

    takeUntil(this.destroy$)
  );

  emailAction$ = this.jwtParams$.pipe(
    switchMap((params: any) => {
      return this.api.get<any>({ path: 'email/' + params.action, params: { ...params } }).pipe(
        tapSuccess((data: any) => {
          if (data) {
            this.profileRef = data?.profileRef;
            this.sus = params.sus;
            this.showQuestionnaire = data?.showQuestionnaire;
            this.candidate$.next(data);
          }
        }),
        switchMap((data: any) => {
          if (params.action === CandidateResponse.DECLINE) {
            this.showQuestionnaire = 0;
            this.candidateResponse = CandidateResponse.DECLINE;
          }
          return this.matchedJobs$.pipe(map(() => data));
        }),
        successData(),
        takeUntil(this.destroy$)
      );
    }),
    takeUntil(this.destroy$)
  );

  matchedJobs$ = this.jwtParams$.pipe(
    switchMap((params: any) => this.candidates.questionnaireJobsMatched(this.profileRef, params.sus)),
    successData(),
    map((data: any) => (this.jobsMatched = data.otherProjectsmatched)),
    takeUntil(this.destroy$)
  );

  minDate = new Date();
  searchCitystate = (searchValue?: string) =>
    this.xref
      .get<DataTable<City>>({
        path: 'cities',
        params: searchValue || '' ? { searchValue } : {}
      })
      .pipe(
        mapSuccessData(data => data.dataTable.map(x => ({ value: x.geoId, text: x.cityState }))),
        takeUntil(this.destroy$)
      );
  WorkStatus$ = this.xref
    .get<WorkStatus[]>({
      path: 'work-statuses'
    })
    .pipe(
      tapSuccess((data: any) => {
        this.workStatus = data;
      }),
      successData(),
      takeUntil(this.destroy$)
    );
  positionTypes$ = this.xref
    .get<PositionType[]>({
      path: 'position-types'
    })
    .pipe(
      tapSuccess((data: any) => {
        this.positionType = data;
      }),
      successData(),
      takeUntil(this.destroy$)
    );
  payRateTypes$ = this.xref
    .get<any[]>({
      path: 'pay-rate-types'
    })
    .pipe(
      tapSuccess((data: any) => {
        this.payType = data;
      }),
      successData(),
      takeUntil(this.destroy$)
    );
  pronouns$ = this.xref
    .get<any[]>({
      path: 'pronouns'
    })
    .pipe(
      tapSuccess((data: any) => {
        this.pronounsList = data;
      }),
      successData(),
      takeUntil(this.destroy$)
    );
  genders$ = this.xref
    .get<any[]>({
      path: 'genders'
    })
    .pipe(
      tapSuccess((data: any) => {
        this.genders = data;
      }),
      successData(),
      takeUntil(this.destroy$)
    );
  ethnicities$ = this.xref
    .get<any[]>({
      path: 'ethnicities'
    })
    .pipe(
      tapSuccess((data: any) => {
        this.races = data;
      }),
      successData(),
      takeUntil(this.destroy$)
    );
  veteranStatuses$ = this.xref
    .get<any[]>({
      path: 'veteran-statuses'
    })
    .pipe(
      tapSuccess((data: any) => {
        this.veteranStatus = data;
      }),
      successData(),
      takeUntil(this.destroy$)
    );
  demographics$ = this.candidate$.pipe(
    switchMap((params: any) => this.candidates.getDemographics(params.profileRef)),
    mapSuccessData((data: any) => data[0]),
    tapSuccess((data: any) => {
      if (data)
        this.candidateInfoForm.patchValue({
          ...data,
          smsOptOut: data.smsOptOut === 1 ? true : false,
          phoneNumber: data.phoneNumber?.replace(/\D/g, '')?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3'),
          geoId: { value: data.geoId, text: data.cityState }
        });
    }),
    takeUntil(this.destroy$)
  );
  workPreferences$ = this.candidate$.pipe(
    switchMap((params: any) => this.candidates.getworkPreferencesAndStatus(params?.profileRef)),
    tapSuccess((data: any) => {
      if (data)
        this.workStatusForm.patchValue({
          ...data,
          availableDate: data?.availableDate ? new Date(Number(data.availableDate)) : new Date(),
          positionTypeId: data?.positionTypesIds
        });
    }),
    takeUntil(this.destroy$)
  );
  eeoc$ = this.candidate$.pipe(
    switchMap((params: any) => this.candidates.getEEOC(params?.profileRef)),
    mapSuccessData((data: any) => data[0]),
    tapSuccess((data: any) => {
      if (data) {
        this.eeocForm.patchValue({
          genderId: data.genderId,
          ethnicityId: data.ethnicityId,
          veteranStatusId: data.veteranStatusId
        });
      }
    }),
    takeUntil(this.destroy$)
  );
  candidateInfoForm = this.fb.group({
    firstName: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required]),
    preferredName: new FormControl<string>(''),
    phoneNumber: new FormControl<string>('', [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]),
    email: new FormControl<string>('', [Validators.email, Validators.required]),
    geoId: new FormControl<any>(null),
    smsOptOut: new FormControl<boolean>(false),
    pronounsId: new FormControl<any>(null),
    pronouns: new FormControl<any>(null)
  });
  workStatusForm = this.fb.group(
    {
      minPayRate: new FormControl<number>(0, { updateOn: 'blur' }),
      maxPayRate: new FormControl<number>(0, { updateOn: 'blur' }),
      availableDate: new FormControl<Date>(new Date()),
      payTypeId: new FormControl<any>(0),
      positionTypeId: new FormControl<any[]>([]),
      workStatusId: new FormControl<any>(null),
      payType: new FormControl<any>(null),
      workStatus: new FormControl<any>(null),
      positionTypeIds: new FormControl<any[]>([])
    },
    {
      validators: [minMaxValidator()]
    }
  );
  eeocForm = this.fb.group({
    genderId: new FormControl<any>(undefined),
    ethnicityId: new FormControl<any>(undefined),
    veteranStatusId: new FormControl<any>(undefined)
  });
  file?: any;
  upload$ = new Subject<File[]>();
  uploadResult$ = this.upload$
    .pipe(
      switchMap(file => (file ? this.resumes.upload(file, this.profileRef, this.sus, true) : of())),
      tap(result => {
        if (result.uploaded === result.progress.length) {
          timer(1000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => { });
        }
      }),
      takeUntil(this.destroy$)
    )
    .subscribe();
  saveProfile$ = new Subject<any>();
  saveProfileSubmit$ = this.saveProfile$
    .pipe(
      switchMap(form => {
        form.markAllAsTouched();
        if (!form.valid || !form.value) return of({ submitted: true as const, valid: false as const });
        const body = {
          ...form.value,
          geoId: form.value.geoId?.geoId,
          payTypeId: form.value?.payTypeId,
          positionTypeId: form.value?.positionTypeId,
          smsOptOut: form.value.smsOptOut ? 1 : 0,
          genderId: form.value.genderId?.genderId,
          raceId: form.value.raceId?.raceId,
          veteranStatusId: form.value.veteranStatusId?.veteranStatusId,
          workStatusId: form.value.workStatusId?.workStatusId,
          pronounsId: form.value.pronounsId?.pronounsId
        };
        return this.jwtParams$.pipe(
          switchMap((Params: any) => this.candidates.candidateQuestionnaire(body, Params.jwt)),
          map(result => ({ submitted: true as const, valid: true as const, result }))
        );
      }),
      takeUntil(this.destroy$)
    )
    .subscribe();

  stepperOrientation: Observable<any>;
  constructor(
    private xref: XrefService,
    private fb: FormBuilder,
    private resumes: ResumesService,
    private activatedRoute: ActivatedRoute,
    private candidates: CandidatesService,

    private api: ApiService,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver //public auth: AuthService
  ) {
    this.stepperOrientation = this.breakpointObserver.observe('(min-width: 800px)').pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.eeocForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.race = this.races.find((x: any) => x.ethnicityId === data.ethnicityId)?.ethnicityDesc;
      this.gender = this.genders.find((x: any) => x.genderId === data.genderId)?.gender;
      this.veteran = this.veteranStatus.find((x: any) => x.veteranStatusId === data.veteranStatusId)?.veteranStatusDesc;
    });
    this.candidateInfoForm.controls.pronounsId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.candidateInfoForm.patchValue({ pronouns: this.pronounsList.find((x: any) => x.id === data)?.pronounsDesc });
    });
    this.workStatusForm.controls.workStatusId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.workStatusForm.patchValue({ workStatus: this.workStatus.find((x: any) => x.workStatusId === data)?.workStatusDesc });
    });
    this.workStatusForm.controls.payTypeId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.workStatusForm.patchValue({ payType: this.payType.find((x: any) => x.payRateId === data)?.payRateDesc });
    });
    this.workStatusForm.controls.positionTypeId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.workStatusForm.patchValue({ positionTypeIds: data });
    });
  }

  submit() {
    this.upload$.next([this.file]);
    this.showQuestionnaire = 0;
    this.candidateResponse = CandidateResponse.INTEREST;
  }

  handleMatchedJob(action: string, jobId: number) {
    this.jwtParams$
      .pipe(
        switchMap((params: any) => this.api.get<any>({ path: `email/${action}`, params: { ...params, jobId } })),
        successData(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const msg = action === CandidateResponse.INTEREST ? 'Interested' : 'Not interested';
        const refreshedJobs = this.jobsMatched.filter((job: any) => job.jobId !== jobId);

        this.snackBar.open(msg, 'Close', { duration: 5 * 1000 });
        this.jobsMatched = [...refreshedJobs];
      });
  }

  onFileSelected($event: any) {
    this.file = $event.target?.files[0];
  }

  getPositionType(id: number) {
    const position = this.positionType.find(position => position.positionTypeId === id);

    return position === -1 ? '' : position.positionType;
  }

  saveDemographics() {
    const body = {
      ...this.candidateInfoForm.value,
      geoId: this.candidateInfoForm.value.geoId?.value,
      smsOptOut: this.candidateInfoForm.value.smsOptOut ? 1 : 0,
      profileRef: this.profileRef,
      phoneNumber: this.candidateInfoForm.value.phoneNumber?.replace(/\D/g, '')
    };
    this.candidates
      .updateDemographics(body)
      .pipe(
        tapLoading(() => (this.loading = true)),
        tapFinished(() => (this.loading = false)),
        tapSuccess(() => {
          this.selectedTab += 1;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  saveWorkPreferencesAndStatus() {
    const body = {
      ...this.workStatusForm.value,
      availableDate: this.workStatusForm.value.availableDate?.getTime(),
      profileRef: this.profileRef
    };
    this.candidates
      .updateWorkPreferencesAndStatus(body)
      .pipe(
        tapLoading(() => (this.loading = true)),
        tapFinished(() => (this.loading = false)),
        tapSuccess(() => {
          this.selectedTab += 1;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  saveEEOC() {
    const body = { ...nullToUndefined(this.eeocForm.value), profileRef: this.profileRef };

    this.candidates
      .updateEEOC(body)
      .pipe(
        tapLoading(() => (this.loading = true)),
        tapFinished(() => (this.loading = false)),
        tapSuccess(() => {
          this.selectedTab += 1;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

function minMaxValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const min = formGroup.get('minPayRate');
    const max = formGroup.get('maxPayRate');
    if (min?.value && max?.value && +max.value < +min.value) {
      formGroup.get('minPayRate')?.setErrors({ max: true });
    } else {
      formGroup.get('minPayRate')?.setErrors(null);
    }
    return null;
  };
}
