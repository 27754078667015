<div class="d-flex mt-2 align-items-center" mat-dialog-title>
  <label class="text-primary">Send Email</label>
  <mat-spinner class="m-3" color="accent" *ngIf="loading" [diameter]="30"></mat-spinner>

  <button mat-mini-fab color="primary" class="ms-auto" (click)="exit()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div mat-dialog-content class="p-3">
  <form [formGroup]="form">
    <div class="d-flex" *ngIf="{ emailList: emailList$ | async } as emails">
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-row align-items-center">
          <label class="body-md"> Template: </label>
          <div class="custom-height ms-2">
            <mat-form-field class="no-underline shrink-form-field" *ngIf="emailTemplates.length > 0" style="max-height: 35px">
              <mat-select formControlName="template">
                <mat-option *ngFor="let item of emailTemplates" [value]="item.templateTypeId + ''">
                  {{ item.templateDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button mat-button color="primary" (click)="clearTemplate()">
            <span>Clear Template</span>
          </button>
          <button mat-stroked-button class="ms-auto" color="primary" (click)="showVariables = !showVariables">
            <mat-icon>
              {{ showVariables ? 'visibility_off' : 'visibility ' }}
            </mat-icon>
            {{ showVariables ? 'Hide Variables' : 'Show Variables' }}
          </button>
        </div>

        <mat-divider class="mt-2"></mat-divider>

        <div *ngIf="recruiterEmail$ | async as data" class="flex-row my-3">
          <label class="body-md"> From: </label>
          <label class="ms-2">
            {{ data.userName }}
          </label>
        </div>

        <mat-divider></mat-divider>

        <div class="d-flex flex-row align-items-center my-2">
          <label class="body-md"> To: </label>

          <label class="body-md ms-2"> {{ emailsList.length }} recipients </label>
          <button mat-stroked-button class="ms-auto" color="primary" (click)="showAllRecipients = !showAllRecipients">
            <mat-icon>
              {{ showAllRecipients ? 'visibility_off' : 'visibility' }}
            </mat-icon>
            {{ showAllRecipients ? 'Hide Recipients' : 'Show Recipients' }}
          </button>
        </div>

        <mat-divider></mat-divider>

        <div class="d-flex flex-row">
          <label class="body-md mt-3">Subject:</label>

          <div class="custom-height mt-2 flex-grow-1" style="height: 3rem">
            <mat-form-field class="ms-2 w-100" style="max-height: 56px">
              <input type="text" matInput formControlName="subject" class="pt-1" />
            </mat-form-field>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="d-flex flex-row mt-3 custom-editor" style="min-width: 58rem !important; height: 22rem !important">
          <div class="NgxEditor__Wrapper p-1 w-100 h-100">
            <ngx-editor-menu [editor]="editor"></ngx-editor-menu>
            <ngx-editor #RTE id="defaultRTE" formControlName="body" [editor]="editor" [placeholder]="'Type here...'" (click)="$event.stopPropagation()"></ngx-editor>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column ms-3 flex-grow-1" style="height: 36rem" id="listbox" *ngIf="showAllRecipients || showVariables">
        <div *ngIf="showAllRecipients" class="ps-2 border" style="overflow-y: auto; overflow-x: auto" [ngClass]="showVariables ? 'h-50' : 'h-100'">
          <label class="body-md mt-2"> Recipients</label>

          <div class="d-flex flex-row align-items-center mt-2" *ngIf="emailListLoading">
            <mat-spinner class="me-3" matSuffix color="accent" diameter="24"> </mat-spinner>
            <label>Loading contact information </label>
          </div>
          <div class="d-flex flex-row align-items-center mt-2" *ngIf="!emailListLoading">
            <mat-icon style="overflow: visible" class="text-warning mat-color-gray-600 md-icon-18">warning</mat-icon>
            <label class="ps-2 pe-2 mat-color-gray-600">Candidates in gray won't receive message; no email address could be found.</label>
          </div>
          <ng-container *ngIf="emailsList.length > 0">
            <div class="d-flex flex-column">
              <mat-chip
                [matTooltip]="user.email"
                style="width: fit-content"
                *ngFor="let user of emailsList"
                [removable]="true"
                (removed)="onUserRemoved(user)"
                [ngClass]="user.email ? 'mat-background-accent-light mt-2' : 'mt-2'">
                {{ user.displayName | titlecase }} <ng-container *ngIf="user.email">({{ user.email }}) </ng-container>

                <mat-icon *ngIf="user.email" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </div>
          </ng-container>
        </div>

        <div *ngIf="showVariables" class="ps-2 border" style="overflow-y: auto; overflow-x: auto" [ngClass]="showAllRecipients ? 'h-50 mt-2' : 'h-100'">
          <label class="body-md mt-2"> Variables</label>
          <ng-container *ngFor="let item of outreachVars">
            <div class="d-flex flex-column">
              <span class="fw-medium mt-2">{{ item.vars[0]?.varTypeDesc }} :</span>
              <mat-chip style="width: fit-content" *ngFor="let v of item.vars" class="mt-2 mat-background-accent-light" draggable="true">
                {{ v.varName }}

                <mat-icon class="md-icon-14" *ngIf="v.varDesc">zoom_out_map</mat-icon>
              </mat-chip>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>

<mat-divider></mat-divider>

<div class="d-flex flex-row p-4" mat-dialog-actions>
  <div class="w-50">
    <button mat-stroked-button color="primary" (click)="exit()">
      <mat-icon class="closeIcon">close</mat-icon>
      Cancel
    </button>
  </div>

  <div class="w-50">
    <div class="d-flex flex-row justify-content-end">
      <button mat-stroked-button color="primary" [disabled]="loading" (click)="previewAction()">
        <mat-icon>preview</mat-icon>
        Preview
      </button>

      <div class="ms-3">
        <button mat-raised-button color="primary" (click)="submit()">
          <mat-icon>send</mat-icon>
          Send
        </button>
      </div>
    </div>
  </div>
</div>

<!--Success modal-->
<ng-template #success let data>
  <div class="d-flex mx-2 mt-2 p-3" mat-dialog-title>
    <label class="text-primary">Success</label>

    <button mat-dialog-close mat-mini-fab color="primary" class="ms-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="mt-1" mat-dialog-content>
    <div fxLayout="row">
      <mat-icon color="primary">check_circle</mat-icon>
      <label class="ms-3 mt-1">
        <b>Email sent</b>
      </label>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button mat-raised-button color="primary" color="primary" mat-dialog-close>Close</button>
  </div>
</ng-template>

<!--Warning modal-->
<ng-template #notSent let data>
  <div class="d-flex mx-2 mt-2 p-3" mat-dialog-title>
    <label class="text-primary">Warning</label>

    <button mat-dialog-close mat-mini-fab color="primary" class="ms-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="mt-1" mat-dialog-content>
    <div fxLayout="row">
      <mat-icon color="primary" class="md-icon-38">warning</mat-icon>
      <label class="ms-3">
        <b class="mb-1"> Email not sent to {{ data.selectedCandidates.length }} recipients</b>
        <br />
        <div class="mt-1">Are you sure you want to exit?</div>
      </label>
    </div>
  </div>

  <mat-divider class="mat-background-gray-300"></mat-divider>

  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button mat-stroked-button color="primary" (click)="closeAll()">Leave without Sending</button>

    <div class="ms-3" [matTooltip]="'Send Email'">
      <button mat-raised-button color="primary" (click)="submit()">Send Email</button>
    </div>
  </div>
</ng-template>

<!--Preview modal-->
<ng-template #preview>
  <div class="d-flex align-items-center mt-2" mat-dialog-title>
    <label class="text-primary">Preview Email</label>

    <mat-spinner class="m-3" color="accent" *ngIf="loading" [diameter]="30"></mat-spinner>

    <button mat-dialog-close mat-mini-fab color="primary" class="ms-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="mt-1" mat-dialog-content>
    <form [formGroup]="form">
      <div class="d-flex">
        <div class="flex-column flex-grow-1">
          <!--From-->
          <div class="m-2">
            <label class="body-md pe-3"> From: </label>
            <label>
              {{ previewResponse?.fromAddress?.Address }}
            </label>
          </div>

          <mat-divider></mat-divider>

          <!--To-->

          <div class="d-flex align-items-center m-2">
            <label class="body-md pe-3"> To: </label>

            <ng-container *ngIf="emailsList.length === 1">
              <mat-chip *ngFor="let user of emailsList" class="mat-background-accent-light">
                {{ user.displayName | titlecase }}
              </mat-chip>
            </ng-container>

            <ng-container *ngIf="emailsList.length > 1">
              <label class="body-md pe-2"> {{ emailsList.length }} recipients </label>
              <button mat-stroked-button color="primary" class="ms-auto" (click)="showAll = !showAll">
                <mat-icon>
                  {{ showAll ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                {{ showAll ? 'Show Less' : 'Show All' }}
              </button>
            </ng-container>
          </div>

          <mat-divider style="font-size: 1rem"></mat-divider>

          <!--Subject-->
          <div class="d-flex m-2">
            <label class="body-md pe-3"> Subject: </label>

            <label>
              {{ previewResponse?.emailHeader }}
            </label>
          </div>

          <mat-divider></mat-divider>

          <!--Body-->
          <div class="d-flex mt-3" style="min-height: 21vw">
            <div *ngIf="previewResponse" class="p-3 emailBody pe-none" [innerHTML]="previewResponse.emailBody | safe : 'html'"></div>
          </div>
        </div>

        <div class="d-flex flex-column ms-3 border" *ngIf="showAll" style="height: 35rem">
          <div class="ps-2" style="overflow-y: auto; overflow-x: auto">
            <label class="body-md mt-2"> Recipients</label>

            <div class="d-flex flex-row align-items-center mt-2">
              <mat-icon style="overflow: visible" class="text-warning">warning</mat-icon>
              <div class="ms-2 mt-1">
                <label> Candidates in gray won't receive message; </label>
                <label> no email address could be found.</label>
              </div>
            </div>
            <ng-container *ngIf="emailsList.length > 0">
              <div class="d-flex flex-column">
                <mat-chip [matTooltip]="user.email" style="width: fit-content" *ngFor="let user of emailsList" [ngClass]="user.email ? 'mat-background-accent-light mt-2' : 'mt-2'">
                  {{ user.displayName | titlecase }} <ng-container *ngIf="user.email">({{ user.email }}) </ng-container>
                </mat-chip>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>

  <mat-divider></mat-divider>

  <div class="d-flex flex-row" mat-dialog-actions>
    <div class="w-100">
      <div class="d-flex flex-row justify-content-end">
        <button mat-dialog-close mat-raised-button color="primary" [disabled]="loading">
          <mat-icon>close</mat-icon>
          Close Preview
        </button>

        <div class="ms-3" [matTooltip]="'Send Email'">
          <button mat-raised-button color="primary" (click)="submit()">
            <mat-icon>send</mat-icon>
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
