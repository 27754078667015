<ng-container>
  <div class="d-flex flex-row" mat-dialog-title>
    <div class="d-flex w-50">
      <label class="text-primary mt-2"> Edit Contact Information </label>
      <mat-spinner class="m-3" color="accent" *ngIf="loading" [diameter]="30"></mat-spinner>
    </div>
    <div class="d-flex w-50 justify-content-end">
      <button mat-dialog-close mat-mini-fab color="primary" class="mt-2" style="width: 34px; height: 34px">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="mt-1" mat-dialog-content>
    <ng-container *ngIf="{ emailTypes: emailTypes$ | async } as types">
      <ng-container>
        <div class="d-flex flex-column">
          <span class="d-block text-black"> Email Address </span>
          <ng-container *ngFor="let item of emails; let i = index">
            <ng-container *ngIf="item.isValid && item.isValid > 0">
              <div class="d-flex flex-row mt-2">
                <div class="custom-height">
                  <mat-form-field style="max-height: 56px; width: 262px">
                    <input #email [(ngModel)]="item.email" type="text" matInput class="pt-1" (input)="emailValueChange(i, email.value)" />
                  </mat-form-field>

                  <mat-error *ngIf="item.valid === 0"> Email address is not valid </mat-error>
                </div>

                <div class="custom-height ms-4">
                  <mat-form-field class="no-underline shrink-form-field" style="max-height: 35px; width: 150px">
                    <mat-select [(ngModel)]="item.emailTypeId">
                      <mat-option *ngFor="let type of types.emailTypes" [value]="type.emailTypeId"> {{ type.emailTypeDesc }} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <mat-checkbox [checked]="item.isPrimary === 1" (change)="item.isPrimary = item.isPrimary === 1 ? 0 : 1; handlePrimaryEmail(item, i)" class="ms-4"> Primary </mat-checkbox>

                <button mat-button class="ms-4" (click)="deleteEmail(i)">
                  <mat-icon class="ms-2 md-icon-22" color="primary">remove_circle</mat-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>
          <div>
            <button mat-button color="primary" (click)="addEmail()">
              <mat-icon class="md-icon-22">add_circle</mat-icon>
              <span>Add Email</span>
            </button>
          </div>

          <mat-divider class="mt-2"></mat-divider>
        </div>

        <div class="d-flex flex-column mt-4">
          <span class="d-block text-black"> Phone Number </span>
          <ng-container *ngFor="let item of phones; let i = index">
            <ng-container *ngIf="item.isValid && item.isValid > 0">
              <div class="d-flex flex-row mt-2">
                <div class="custom-height">
                  <mat-form-field style="max-height: 56px">
                    <input
                      #phone
                      placeholder="(___)___-___"
                      [(ngModel)]="item.phoneNumber"
                      [name]="item.phoneNumber"
                      type="phone"
                      (input)="phoneNumberValueChange(i, phone.value)"
                      matInput
                      class="pt-1"
                      appPhoneMask />
                  </mat-form-field>
                  <mat-error *ngIf="item.valid === 0"> Phone number is not valid </mat-error>
                </div>

                <mat-checkbox [(ngModel)]="item.isPrimary" (change)="item.isPrimary = item.isPrimary === 1 ? 0 : 1; handlePrimaryPhone(item, i)" class="ms-4"> Primary </mat-checkbox>
                <button mat-button (click)="deletePhoneNumber(i)">
                  <mat-icon class="ms-4 md-icon-22" color="primary">remove_circle</mat-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>
          <div>
            <button mat-button color="primary" (click)="addPhoneNumber()">
              <mat-icon class="md-icon-22">add_circle</mat-icon>
              <span>Add Phone Number</span>
            </button>
          </div>
          <mat-divider class="mt-2"></mat-divider>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <mat-divider class="mat-background-gray-300"></mat-divider>

  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" [disabled]="loading" (click)="save()">Save</button>
  </div>
</ng-container>
