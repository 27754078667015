// api
export enum ApiResponseStatus {
  invalidCredentials = 'INVALID_AUTH_CREDENTIALS',
  invalidToken = 'INVALID_AUTH_TOKEN',
  payloadTooLarge = 'PAYLOAD_TOO_LARGE',
  reusedPassword = 'REUSED_PASSWORD',
  invalidPassword = 'INVALID_PASSWORD',
  invalidPasswordReqs = 'INVALID_PASSWORD_REQS',
  invalidVerificationCode = 'INVALID_VERIFICATION_CODE'
}

// intake form
export enum CriteriaKeys {
  JobRoles = 'jobRoles',
  Skills = 'skills',
  Certifications = 'certifications'
}

// jobPosting/allRules
export enum SearchCriteriaRuleIds {
  careerLevel = 1005,
  roles = 1001,
  companies = 1010,
  experienceReqs = 1020,
  industries = 1030,
  location = 2000,
  workStatus = 2020,
  positionTypes = 2030,
  payBands = 2050,
  skills = 3000,
  certifications = 4000,
  education = 4001,
  preferredMajor = 4002
}

// jobPosting/searchCriteria, jobPosting/allRules
export enum SearchCriteriaRuleKeys {
  jobId = 'jobId',
  isAdvancedSearch = 'isAdvancedSearch',
  certifications = 'certifications',
  companies = 'companies',
  dataSource = 'dataSource',
  degreeTypes = 'degreeTypes',
  excludeCompanies = 'excludeCompanies',
  excludedCandidates = 'excludedCandidates',
  experienceReqs = 'experienceReqs',
  industries = 'industries',
  jobRoleLevels = 'jobRoleLevels',
  jobRoles = 'jobRoles',
  jobSubFamilies = 'jobSubFamilies',
  jurisdiction = 'jurisdiction',
  languages = 'languages',
  offlimitscompanies = 'offlimitscompanies',
  payBands = 'payBands',
  positionTypes = 'positionTypes',
  preferredMajors = 'preferredMajors',
  preferredSchools = 'preferredSchools',
  preferredSpecializations = 'preferredSpecializations',
  priorJobRoles = 'priorJobRoles',
  skills = 'skills',
  softSkills = 'softSkills',
  workStatuses = 'workStatuses'
}

export enum RulerFactoryOption {
  Start = 'START',
  End = 'END',
  Default = 'DEFAULT'
}

export enum JobType {
  DIRECT_HIRE = 1000,
  STAFFING = 2000
}

export enum CandidateRestrictionStatus {
  NON_RESTRICTED = 0,
  RESTRICTED = 1
}

export enum CandidateFollowStatus {
  NON_FOLLOWED = 0,
  FOLLOWED = 1
}

export enum OmniSearchFilters {
  CANDIDATES = 'Candidates',
  JOBS = 'Jobs'
}

export enum CandidateResponse {
  INTEREST = 'interest',
  DECLINE = 'decline'
}

export enum MemberLead {
  NOT_FOUND = -1,
  NOT_LEAD = 0,
  IS_LEAD = 1
}

export enum TooltipText {
  searchCriteriaTemplate = 'This is the currently applied search template. If you adjust the selection and update your search, the underlying criteria from the newly applied template will be applied to this job.',
  saveSearchBtn = 'Click to save the current search criteria as a template. This will allow you to use this criteria for future job searches.'
}
